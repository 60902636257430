import { default as _91id_939e8MhQqV2hMeta } from "/app/frontend/pages/app/chat/[id].vue?macro=true";
import { default as newVwIDaZkBcWMeta } from "/app/frontend/pages/app/chat/new.vue?macro=true";
import { default as index7cLmkruPXBMeta } from "/app/frontend/pages/app/index.vue?macro=true";
import { default as confirmWlRAzhKd9IMeta } from "/app/frontend/pages/app/subscription/confirm.vue?macro=true";
import { default as manageF22KTbr4q6Meta } from "/app/frontend/pages/app/subscription/manage.vue?macro=true";
import { default as indexveLFljomDmMeta } from "/app/frontend/pages/index.vue?macro=true";
import { default as index9FxoN6OoXyMeta } from "/app/frontend/pages/login/index.vue?macro=true";
import { default as privacy_45policyI5xEbV1cDeMeta } from "/app/frontend/pages/privacy-policy.vue?macro=true";
import { default as termsvSKoakst7AMeta } from "/app/frontend/pages/terms.vue?macro=true";
export default [
  {
    name: "app-chat-id",
    path: "/app/chat/:id()",
    component: () => import("/app/frontend/pages/app/chat/[id].vue")
  },
  {
    name: "app-chat-new",
    path: "/app/chat/new",
    component: () => import("/app/frontend/pages/app/chat/new.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/app/frontend/pages/app/index.vue")
  },
  {
    name: "app-subscription-confirm",
    path: "/app/subscription/confirm",
    component: () => import("/app/frontend/pages/app/subscription/confirm.vue")
  },
  {
    name: "app-subscription-manage",
    path: "/app/subscription/manage",
    component: () => import("/app/frontend/pages/app/subscription/manage.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/frontend/pages/login/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/app/frontend/pages/privacy-policy.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/app/frontend/pages/terms.vue")
  }
]