import payload_plugin_NEPHq8jEUC from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Frd8gASYWa from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_XE4XCJRsZL from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_2MWhypiRxk from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
export default [
  payload_plugin_NEPHq8jEUC,
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  plugin_XE4XCJRsZL,
  plugin_client_2MWhypiRxk
]