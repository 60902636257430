<template>
  <NuxtLayout :name="isAppRoute ? 'app' : 'default'">
    <NuxtRouteAnnouncer />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
// if route starts with /app use the app layout
import { useRoute } from "vue-router";

const route = useRoute();
const isAppRoute = computed(() => {
  return route.path.startsWith("/app");
});
</script>
