<script setup lang="ts">
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

const route = useRoute();
if (props.error?.statusCode === 401 && route.path.startsWith("/app")) {
  window.location.href = "/login";
}
</script>

<template>
  <div class="grid h-screen place-content-center bg-white px-4">
    <div class="text-center max-w-2xl">
      <h1 class="text-6xl font-black text-gray-200" v-if="props.error?.statusCode === 404">Page not found</h1>
      <h1 class="text-6xl font-black text-gray-200" v-else>An error occurred - {{ props.error?.statusCode }}</h1>

      <div class="mt-4"></div>

      <p class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Uh-oh!</p>

      <p class="mt-4 text-gray-500">
        It seems something went wrong. If the problem persists, <br />
        drop me an email at <a href="mailto:hey@betterdanish.dk" class="underline">hey@betterdanish.dk</a>
      </p>

      <div class="mt-8"></div>
      <a href="/">
        <Button> Go Back Home </Button>
      </a>
    </div>
  </div>
</template>
